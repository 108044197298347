import type { Declaration, Workflow } from 'types/entities';
import {
  getInFavorOrAgainst,
  getIvaInFavor,
  getTotalToPayOfPeriod,
} from './getDeclarationTotalCalculations';

// eslint-disable-next-line import/prefer-default-export
export const getDataFromDeclarations = (
  declarations: Declaration[],
  preferredRegimeSatKey?: number | null,
  workflow?: Workflow,
) => {
  const declarationSatKeys = declarations
    .filter((declaration) => declaration.is_active)
    .map((declaration) => declaration.fiscal_regime.sat_key);
  return declarations
    .filter((declaration) => declaration.is_active)
    .reduce(
      (data, declaration) => {
        const { declaration_income, declaration_expense } = declaration;
        let totalIncomes = 0;
        if (
          !preferredRegimeSatKey ||
          !declarationSatKeys.includes(preferredRegimeSatKey) ||
          declaration.fiscal_regime.sat_key === preferredRegimeSatKey
        ) {
          totalIncomes = +declaration_income.total;
        }

        const totalExpenses = +declaration_expense.total;
        const salaryRetentions =
          +declaration.declaration_income.taxes_withheld_of_payroll;
        const totalLateFees = +(declaration.total_late_fees || 0);
        const ivaTotal = +declaration.iva_total;
        const ivaInFavorFromPeriods = getIvaInFavor(declaration, workflow);

        const ivaInFavorOrAgainst = getInFavorOrAgainst({
          declaration,
          totalKey: 'ivaTotal',
          favorKey: 'ivaInFavor',
          unduePaymentKey: 'unduePaymentIvaInFavor',
          declarations,
          workflow,
        });
        const isrInFavorOrAgainst = getInFavorOrAgainst({
          declaration,
          totalKey: 'isrTotal',
          favorKey: 'isrInFavor',
          unduePaymentKey: 'unduePaymentIsrInFavor',
          declarations,
        });

        const totalToPayOfPeriod = getTotalToPayOfPeriod(
          declaration,
          ivaInFavorOrAgainst,
          isrInFavorOrAgainst,
        );
        return {
          totalIncome: data.totalIncome + totalIncomes,
          totalExpense: data.totalExpense + totalExpenses,
          profitOrLoss: data.profitOrLoss + (totalIncomes - totalExpenses),
          isrToPay: data.isrToPay + isrInFavorOrAgainst,
          salaryRetentions: data.salaryRetentions + salaryRetentions,
          totalLateFees: data.totalLateFees + totalLateFees,
          totalToPay: data.totalToPay + totalToPayOfPeriod,
          ivaInFavorFromPeriods:
            data.ivaInFavorFromPeriods + ivaInFavorFromPeriods,
          ivaInFavorOrAgainst: data.ivaInFavorOrAgainst + ivaInFavorOrAgainst,
          ivaTotal: data.ivaTotal + ivaTotal,
        };
      },
      {
        totalIncome: 0,
        totalExpense: 0,
        profitOrLoss: 0,
        ivaInFavorOrAgainst: 0,
        isrToPay: 0,
        salaryRetentions: 0,
        totalLateFees: 0,
        totalToPay: 0,
        ivaInFavorFromPeriods: 0,
        ivaTotal: 0,
      },
    );
};
